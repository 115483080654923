<template>
  <v-container fluid>
    <router-view class="fade-motion"></router-view>
  </v-container>
</template>
<script>
export default {
  mounted() {
    this.$parent.$parent.$parent.topBar = true;

    if (this.$vuetify.breakpoint.mobile == false) {
      this.$parent.$parent.$parent.drawer = true;
    }
  },
};
</script>
<style lang=""></style>
